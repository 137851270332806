import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface PermissionRequiredComponentProps {
  permissionName: string;
  children: React.ReactNode;
}

const PermissionRequiredComponent: React.FC<
  PermissionRequiredComponentProps
> = ({ permissionName, children }) => {
  const permissions = useSelector((state: RootState) => state.user.permissions);

  if (permissions === undefined || permissions.indexOf(permissionName) < 0) {
    return <></>;
  }
  return <>{children}</>;
};

export default PermissionRequiredComponent;
