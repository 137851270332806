import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { deleteItem, getItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, warningAlert } from "../utils/messages";
import Plot, { newPlot } from "../../models/plots";
import Block from "../../models/blocks";

interface PlotDeleteModalProps {
  show: boolean;
  plot?: Plot;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const PlotDeleteModal: React.FC<PlotDeleteModalProps> = ({
  show,
  plot,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const [, setEditingPlot] = useState<Plot>(plot ? plot : newPlot());
  const [title, setTitle] = useState<string>("");
  const message = plot
    ? `Está seguro de que quiere eliminar el Lote del Loteamiento: ${title}`
    : "Está seguro de que quiere eliminar el Lote del Loteamiento:";

  const onDeleteClick = async () => {
    if (plot !== undefined) {
      setSubmitting(true);
      const plotStatus = await deleteItem<Plot>(`/plots/${plot.id}/`, plot);

      if (plotStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (plotStatus.detail !== undefined) {
          message = plotStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  const fetchBlockAndSetTitle = async (id: number) => {
    const blockStatus = await getItem<Block>(`/blocks/${id}/`);
    if (blockStatus.status === SUCCESS) {
      if (blockStatus.data !== undefined) {
        setTitle(`${plot?.identifier} - ${blockStatus.data.identifier}`);
      }
    } else {
      const message = blockStatus.detail
        ? blockStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  useEffect(() => {
    setEditingPlot(plot ? plot : newPlot());
    if (plot?.blockId) {
      fetchBlockAndSetTitle(plot.blockId);
    } else {
      setTitle(plot?.identifier || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plot]);

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Eliminar</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <h5>
                <span className="text-danger">¡Precaución!</span>Esta acción no
                se puede deshacer.
              </h5>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onDeleteClick} color="danger">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Eliminando..." : "Eliminar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default PlotDeleteModal;
