import { LAND_DEVELOPMENTS_RETRIEVE, USERS_RETRIEVE } from "./auth/permissions";
import DashboardPage from "./dashboard-pages/DashboardPage";
import LandDevelopmentCrudPage from "./dashboard-pages/LandDevelopmentCrudPage";
import LandDevelopmentDetailPage from "./dashboard-pages/LandDevelopmentDetailPage";
import LandDevelopmentMapPage from "./dashboard-pages/LandDevelopmentMapPage";
import UserCrudPage from "./dashboard-pages/UserCrudPage";

interface Route {
  path: string;
  exact?: boolean | undefined;
  name: string;
  component?: any;
  permissionName?: string;
}

interface RouteMap {
  [index: string]: Route;
}

const routes: RouteMap = {
  dashboard: {
    path: "/dashboard/",
    name: "Inicio",
    exact: true,
    component: DashboardPage,
  },
  users: {
    path: "/users/",
    name: "Usuarios",
    exact: true,
    component: UserCrudPage,
    permissionName: USERS_RETRIEVE,
  },
  landDevelopmentDetail: {
    path: "/developments/configure/:id/",
    name: "Configurar Loteamiento",
    exact: true,
    component: LandDevelopmentDetailPage,
    permissionName: LAND_DEVELOPMENTS_RETRIEVE,
  },
  landDevelopmentMap: {
    path: "/developments/map/:id/",
    name: "Mapa de Loteamiento",
    exact: true,
    component: LandDevelopmentMapPage,
    permissionName: LAND_DEVELOPMENTS_RETRIEVE,
  },
  landDevelopments: {
    path: "/land_developments/",
    name: "Loteamientos",
    exact: true,
    component: LandDevelopmentCrudPage,
    permissionName: LAND_DEVELOPMENTS_RETRIEVE,
  },
};

export const getRoutePath = (key: string) => {
  return routes[key].path;
};
export const getRouteName = (key: string) => {
  return routes[key].name;
};

export default routes;
