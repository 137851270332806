import MapPreview from "../components/map/MapPreview";

const LandDevelopmentMapPage = () => {
  return (
    <>
      <MapPreview />
    </>
  );
};

export default LandDevelopmentMapPage;
