import { CCard, CCardBody, CCol, CContainer, CRow } from "@coreui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { warningAlert } from "../utils/messages";
import { getItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import LandDevelopment from "../../models/land_development";
import MapPage from "./MapPage";

const MapPreview = () => {
  const [landDevelopment, setLandDevelopment] = useState<
    LandDevelopment | undefined
  >(undefined);

  const [, setLoading] = useState(true);

  const { id } = useParams<{ id: string }>();

  const getInitialValues = async () => {
    setLoading(true);
    const landDevelopmentPromise = fetchLandDevelopment(Number(id));
    await landDevelopmentPromise;
    setLoading(false);
  };

  const fetchLandDevelopment = async (id: number) => {
    const landDevelopmentStatus = await getItem<LandDevelopment>(
      `/land_developments/${id}/`
    );
    if (landDevelopmentStatus.status === SUCCESS) {
      if (landDevelopmentStatus.data !== undefined) {
        setLandDevelopment(landDevelopmentStatus.data);
      }
    } else {
      const message = landDevelopmentStatus.detail
        ? landDevelopmentStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  useEffect(() => {
    if (id === undefined || isNaN(Number(id))) {
      setLoading(false);

      return;
    }
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol lg="12">
            <CCard>
              <CCardBody>
                <div className="p-2 ">
                  <h3>
                    {landDevelopment
                      ? `Previsualización de Mapa: ${landDevelopment.identifier}`
                      : "Previsualización de Mapa"}
                  </h3>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="12">
            <MapPage />
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default MapPreview;
