import React from "react";

import { Route } from "react-router-dom";

import { useSelector } from "react-redux";

import { RootState } from "../store";
import PermissionRequired from "../components/permissions/PermissionRequired";

interface PermissionRequiredProps {
  permissionName: string;
  children: React.ReactNode;
}

const PermissionRequiredRoute = (props: PermissionRequiredProps) => {
  const permissions = useSelector((state: RootState) => state.user.permissions);

  if (
    permissions === undefined ||
    permissions.indexOf(props.permissionName) < 0
  ) {
    return <PermissionRequired />;
  }
  return <Route {...props}></Route>;
};

export default PermissionRequiredRoute;
