import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import PlotForm from "./PlotForm";
import Plot, { newPlot } from "../../models/plots";

interface PlotUpdateModalProps {
  show: boolean;
  plot?: Plot;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const PlotUpdateModal: React.FC<PlotUpdateModalProps> = ({
  show,
  plot,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialPlot, setInitialPlot] = useState<Plot>(plot ? plot : newPlot());

  const modalTitle = plot?.identifier
    ? `Editar Lote: ${plot.identifier}`
    : `Crear Lote nuevo`;

  const onClose = () => {
    setInitialErrors({});
    setInitialPlot(newPlot());
    onCancel();
  };
  useEffect(() => {
    setInitialPlot(plot ? plot : newPlot());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plot]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <PlotForm
            initialPlot={initialPlot}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default PlotUpdateModal;
