import { getAbsoluteUrl } from "./urls";
import { fetchAuthenticated } from "./utils";
import { HTTP_OK } from "../utils/constants/http";
import { FAILED, SUCCESS } from "../utils/constants/tags";

export interface JWTRequestStatus {
  status: string;
  message?: string;
  accessToken?: string;
  email?: string;
}
export interface UserDataRequestStatus {
  status: string;
  message?: string;
  userData?: {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
  };
}

export interface UserPermissionsRequestStatus {
  status: string;
  message?: string;
  data?: {
    count: number;
    items: string[];
  };
}

interface SuccessfulJWTRequest {
  status: string;
  data: {
    access: string;
    refresh: string;
    email: string | null;
  };
}
interface FailedJWTRequest {
  status: string;
  message: string;
}
interface SuccessfulUserDataRequest {
  status: string;
  data: {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
  };
}
interface FailedUserDataRequest {
  status: string;
  message: string;
}

type JWTResponse = SuccessfulJWTRequest | FailedJWTRequest;
type UserDataResponse = SuccessfulUserDataRequest | FailedUserDataRequest;

export const getJWTToken = async (
  email: string,
  password: string
): Promise<JWTRequestStatus> => {
  const url = getAbsoluteUrl("/auth/token/");
  const data = {
    email: email,
    password: password,
  };
  let response: Response = { ok: false } as Response;
  try {
    response = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify(data),
    });
  } catch (error) {
    return {
      status: FAILED,
      message: "Server not Found",
    };
  }

  if (!response.ok) {
    return {
      status: FAILED,
      message: "Network Error",
    };
  }
  const responseData: JWTResponse = await response.json();

  if (response.status !== HTTP_OK) {
    let failedResponse = responseData as FailedJWTRequest;
    return {
      status: FAILED,
      message: failedResponse.message,
    };
  }

  let successfulResponse = responseData as SuccessfulJWTRequest;

  return {
    status: SUCCESS,
    accessToken: successfulResponse.data.access,
  };
};

export const refreshJWTToken = async (): Promise<JWTRequestStatus> => {
  const url = getAbsoluteUrl("/auth/token_refresh/");
  const data = {};
  let response: Response = { ok: false } as Response;
  try {
    response = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify(data),
    });
  } catch (error) {
    return {
      status: FAILED,
      message: "Server not Found",
    };
  }

  if (!response.ok) {
    return {
      status: FAILED,
      message: "Network Error",
    };
  }

  const responseData: JWTResponse = await response.json();

  if (response.status !== HTTP_OK) {
    let failedResponse = responseData as FailedJWTRequest;
    return {
      status: FAILED,
      message: failedResponse.message,
    };
  }
  let successfulResponse = responseData as SuccessfulJWTRequest;

  return {
    status: SUCCESS,
    accessToken: successfulResponse.data.access,
  };
};
export const clearRefreshToken = async (): Promise<JWTRequestStatus> => {
  const url = getAbsoluteUrl("/auth/clear_refresh_token/");
  const data = {};
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify(data),
    });
  } catch (error) {
    return {
      status: FAILED,
      message: "Server not Found",
    };
  }
  if (!response.ok) {
    return {
      status: FAILED,
      message: "Network Error",
    };
  }

  const responseData: JWTResponse = await response.json();

  if (response.status !== HTTP_OK) {
    let failedResponse = responseData as FailedJWTRequest;
    return {
      status: FAILED,
      message: failedResponse.message,
    };
  }

  return {
    status: SUCCESS,
    accessToken: "",
  };
};

export const getUserData = async (): Promise<UserDataRequestStatus> => {
  const url = getAbsoluteUrl("/auth/user/");
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      method: "GET",
      credentials: "include",
    });
  } catch (error) {
    return {
      status: FAILED,
      message: "Server not Found",
    };
  }

  if (!response.ok) {
    return {
      status: FAILED,
      message: "Network Error",
    };
  }

  const responseData: UserDataResponse = await response.json();

  if (response.status !== HTTP_OK) {
    let failedResponse = responseData as FailedUserDataRequest;
    return {
      status: FAILED,
      message: failedResponse.message,
    };
  }

  if (responseData.status !== SUCCESS) {
    let failedResponse = responseData as FailedUserDataRequest;
    return {
      status: FAILED,
      message: failedResponse.message,
    };
  }

  let successfulResponse = responseData as SuccessfulUserDataRequest;

  return {
    status: SUCCESS,
    userData: {
      id: successfulResponse.data.id,
      username: successfulResponse.data.username,
      firstName: successfulResponse.data.firstName,
      lastName: successfulResponse.data.lastName,
      email: successfulResponse.data.email,
    },
  };
};

export const getUserPermissions =
  async (): Promise<UserPermissionsRequestStatus> => {
    const url = getAbsoluteUrl("/auth/permissions/");
    let response: Response = { ok: false } as Response;
    try {
      response = await fetchAuthenticated(url, {
        method: "GET",
        credentials: "include",
      });
    } catch (error) {
      return {
        status: FAILED,
        message: "Server not Found",
      };
    }

    if (!response.ok) {
      return {
        status: FAILED,
        message: "Network Error",
      };
    }

    const responseData: UserPermissionsRequestStatus = await response.json();

    if (response.status !== HTTP_OK) {
      let failedResponse = responseData as FailedUserDataRequest;
      return {
        status: FAILED,
        message: failedResponse.message,
      };
    }

    if (responseData.status !== SUCCESS) {
      let failedResponse = responseData as FailedUserDataRequest;
      return {
        status: FAILED,
        message: failedResponse.message,
      };
    }

    return {
      status: SUCCESS,
      data: responseData.data,
    };
  };
