import LandDevelopmentDetail from "../components/land_developments/LandDevelopmentDetail";

const LandDevelopmentDetailPage = () => {
  return (
    <>
      <LandDevelopmentDetail />
    </>
  );
};

export default LandDevelopmentDetailPage;
