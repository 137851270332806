import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
  CLink,
  CTooltip,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import LandDevelopment from "../../models/land_development";
import LandDevelopmentUpdateModal from "./LandDevelopmentModal";
import LandDevelopmentDeleteModal from "./LandDevelopmentDeleteModal";
import { defaultValueOnUndefined } from "../../utils/fields";
import RecordImportModal from "./ImportModal";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import {
  LAND_DEVELOPMENTS_CREATE,
  LAND_DEVELOPMENTS_DESTROY,
  LAND_DEVELOPMENTS_UPDATE,
} from "../../auth/permissions";
import ImportKMLModal from "./ImportKMLModal";
import { SITE_URL } from "../../config/site";

const ITEMS_PER_PAGE = 20;

const LandDevelopmentList = () => {
  const fields = [
    {
      key: "identifier",
      _classes: ["text-center"],
      label: "Nombre",
    },
    {
      key: "description",
      _classes: ["text-center"],
      label: "Link de contacto",
    },
    {
      key: "obs",
      _classes: ["text-center"],
      label: "Observación",
    },
    {
      key: "mapUrl",
      _classes: ["text-center"],
      label: "URL de mapa",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showImportKMLModal, setShowImportKMLModal] = useState(false);

  const [landDevelopment, setLandDevelopment] = useState<
    LandDevelopment | undefined
  >(undefined);
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [landDevelopments, setLandDevelopments] = useState<LandDevelopment[]>(
    []
  );
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    return map;
  };

  const fetchLandDevelopments = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const landDevelopmentsStatus = await getList<LandDevelopment>(
      "/land_developments/",
      limit,
      offset,
      urlParams
    );
    if (landDevelopmentsStatus.status === SUCCESS) {
      if (landDevelopmentsStatus.data !== undefined) {
        const count = landDevelopmentsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setLandDevelopments(landDevelopmentsStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = landDevelopmentsStatus.detail
        ? landDevelopmentsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setShowEditModal(true);
  };

  const onUpdateClick = (client: LandDevelopment) => {
    setLandDevelopment(client);
    setShowEditModal(true);
  };

  const onDeleteClick = (client: LandDevelopment) => {
    setLandDevelopment(client);
    setShowDeleteModal(true);
  };

  const onEditCancel = () => {
    setLandDevelopment(undefined);
    setShowEditModal(false);
  };

  const onEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowEditModal(false);
    setLandDevelopments([]);
    setLoading(true);
    fetchLandDevelopments();
  };

  const onDeleteCancel = () => {
    setLandDevelopment(undefined);
    setShowDeleteModal(false);
  };

  const onDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowDeleteModal(false);
    setLandDevelopments([]);
    setLoading(true);
    fetchLandDevelopments();
  };

  const onImportClick = () => {
    setShowImportModal(true);
  };

  const onImportCancel = () => {
    setShowImportModal(false);
  };

  const onImportSuccess = () => {
    successAlert(
      "Importaciòn iniciada con exito. Podría demorarse unos minutos antes de ver las entradas en la lista!"
    );
    setShowImportModal(false);
    setLandDevelopments([]);
    setLoading(true);
    fetchLandDevelopments();
  };

  const onImportKMLClick = () => {
    setShowImportKMLModal(true);
  };

  const onImportKMLCancel = () => {
    setShowImportKMLModal(false);
  };

  const onImportKMLSuccess = () => {
    successAlert(
      "Importaciòn KML iniciada con exito. Podría demorarse unos minutos antes de ver las entradas en la lista!"
    );
    setShowImportKMLModal(false);
    setLandDevelopments([]);
    setLoading(true);
    fetchLandDevelopments();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("search", search);
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchLandDevelopments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("landDevelopments")) {
        return;
      }
      fetchLandDevelopments();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Loteamientos</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent
                  permissionName={LAND_DEVELOPMENTS_CREATE}
                >
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent
                  permissionName={LAND_DEVELOPMENTS_CREATE}
                >
                  <CButton
                    color="success"
                    className="float-right"
                    onClick={onImportClick}
                  >
                    <i className="fa fa-file-excel" />
                    &nbsp; Importar XLSX
                  </CButton>
                </PermissionRequiredComponent>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent
                  permissionName={LAND_DEVELOPMENTS_CREATE}
                >
                  <CButton
                    color="info"
                    className="float-right"
                    onClick={onImportKMLClick}
                  >
                    <i className="fa fa-map" />
                    &nbsp; Importar KML
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={landDevelopments}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    description: (item: LandDevelopment) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.description)}
                        </td>
                      );
                    },
                    obs: (item: LandDevelopment) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.obs)}
                        </td>
                      );
                    },
                    mapUrl: (item: LandDevelopment) => {
                      const uri = "/internal_map/:id/".replace(
                        ":id",
                        String(item.id)
                      );
                      const url = SITE_URL ? `${SITE_URL}${uri}` : uri;
                      return <td className="text-center">{url}</td>;
                    },
                    actions: (item: LandDevelopment) => {
                      const mapButton = (
                        <CTooltip content={"Ver Mapa"}>
                          <CLink to={`/developments/map/${item.id}/`}>
                            <CButton className="text-white" color="success">
                              <i className="fa fa-map"></i>
                            </CButton>
                          </CLink>
                        </CTooltip>
                      );
                      const configureButton = (
                        <CTooltip content={"Configurar"}>
                          <CLink to={`/developments/configure/${item.id}/`}>
                            <CButton className="text-white" color="primary">
                              <i className="fa fa-cog"></i>
                            </CButton>
                          </CLink>
                        </CTooltip>
                      );
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={LAND_DEVELOPMENTS_UPDATE}
                        >
                          <CTooltip content={"Editar"}>
                            <CButton
                              className="text-white"
                              color="warning"
                              onClick={() => {
                                onUpdateClick(item);
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const deleteButton = (
                        <PermissionRequiredComponent
                          permissionName={LAND_DEVELOPMENTS_DESTROY}
                        >
                          <CTooltip content={"Eliminar"}>
                            <CButton
                              className="text-white"
                              color="danger"
                              onClick={() => {
                                onDeleteClick(item);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {mapButton}
                            {configureButton}
                            {editButton}
                            {deleteButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <LandDevelopmentUpdateModal
        show={showEditModal}
        onCancel={onEditCancel}
        onSuccess={onEditSuccess}
        landDevelopment={landDevelopment}
      />
      <LandDevelopmentDeleteModal
        show={showDeleteModal}
        onCancel={onDeleteCancel}
        onSuccess={onDeleteSuccess}
        landDevelopment={landDevelopment}
      />
      <RecordImportModal
        show={showImportModal}
        onCancel={onImportCancel}
        onSuccess={onImportSuccess}
      ></RecordImportModal>
      <ImportKMLModal
        show={showImportKMLModal}
        onCancel={onImportKMLCancel}
        onSuccess={onImportKMLSuccess}
      ></ImportKMLModal>
    </>
  );
};

export default LandDevelopmentList;
