import {
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CInputFile,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

import { warningAlert } from "../utils/messages";
import { FieldErrors } from "../form/FieldErrors";
import Errors, { getFieldErrors } from "../../models/errors";
import { getAbsoluteUrl } from "../../api/urls";
import { fetchAuthenticated } from "../../api/utils";

interface RecordImportModalProps {
  show: boolean;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ImportKMLModal: React.FC<RecordImportModalProps> = ({
  show,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = "Suba el Archivo kml correspondiente para importar";

  const [errors] = useState<Errors>({});

  const [loadedKmlPath, setLoadedKmlPath] = useState<string | undefined>("");
  const kml = useRef<HTMLInputElement>(null);
  const kmlFileName = useRef<string>("");
  const kmlUploaded = useRef<boolean>(false);
  const kmlModified = useRef<boolean>(false);

  const onKMLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    kmlModified.current = true;
    let fileParts = e.target.value.split("\\");
    kmlFileName.current = fileParts[fileParts.length - 1];
    setLoadedKmlPath(kmlFileName.current);
  };

  const handleKMLUpload = async () => {
    if (!kml.current || !kml.current.files || !kml.current.files[0]) {
      warningAlert("Debe seleccionar un archivo kml");
      return false;
    }

    const formData = new FormData();
    formData.append("file", kml.current.files[0]);

    setSubmitting(true);

    const url = getAbsoluteUrl("/land_developments/upload_kml/");
    const uploadStatus = await fetchAuthenticated(url, {
      method: "POST",
      body: formData,
    });

    if (uploadStatus.status === 200) {
      onSuccess();
      return true;
    } else {
      const errorData = await uploadStatus.json();
      if (errorData.detail) {
        warningAlert(errorData.detail);
      } else {
        warningAlert("Ocurrió un error durante la carga del archivo.");
      }
      return false;
    }
  };
  const onSave = async () => {
    const success = await handleKMLUpload();

    if (success) {
      clearForm();
    }
    setSubmitting(false);
  };

  const clearForm = () => {
    setLoadedKmlPath("");
    if (kml.current) {
      kml.current.value = "";
    }
    kmlFileName.current = "";
    kmlUploaded.current = false;
    kmlModified.current = false;
  };

  return (
    <CModal show={show} className="modal-success" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Importar Datos</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <h5>
                La importación de archivos puede demorar unos minutos. Por favor
                espere
              </h5>
            </CCol>
          </CRow>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>archivo excel (.kml):</CLabel>
              </CCol>
              <CCol>
                <CInputFile
                  accept=".kml"
                  type="file"
                  innerRef={kml}
                  onChange={onKMLChange}
                ></CInputFile>
                {loadedKmlPath ? (
                  <small>
                    Seleccionado actualmente:
                    {loadedKmlPath}
                  </small>
                ) : (
                  <></>
                )}

                <FieldErrors
                  errors={getFieldErrors("kmlFile", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onSave} color="success">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Guardando..." : "Importar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ImportKMLModal;
