import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getItem, updateItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, warningAlert } from "../utils/messages";
import Plot, { newPlot } from "../../models/plots";
import Block from "../../models/blocks";

interface PlotToggleStatusModalProps {
  show: boolean;
  plot?: Plot;
  taken?: boolean;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const PlotToggleStatusModal: React.FC<PlotToggleStatusModalProps> = ({
  show,
  plot,
  taken,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [editingPlot, setEditingPlot] = useState<Plot>(plot ? plot : newPlot());

  const [title, setTitle] = useState<string>("");

  const message = plot
    ? `Está seguro de que quiere cambiar la disponibilidad del lote: ${title}`
    : "Está seguro de que quiere cambiar la disponibilidad del lote";
  const color = taken ? "success" : "secondary";
  const message2 = `El nuevo estado del lote será: ${
    taken ? "Disponible" : "NO Disponible"
  }`;

  const onDeleteClick = async () => {
    if (editingPlot !== undefined) {
      setSubmitting(true);
      let toSendPlot: Plot = {
        ...editingPlot,
        isTaken: taken !== undefined ? !taken : true,
      };
      const plotStatus = await updateItem<Plot>(
        `/plots/${editingPlot.id}/`,
        toSendPlot
      );

      if (plotStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (plotStatus.detail !== undefined) {
          message = plotStatus.detail;
        }
        errorAlert(message);
      } else {
        setTitle("");
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  const fetchBlockAndSetTitle = async (id: number) => {
    const blockStatus = await getItem<Block>(`/blocks/${id}/`);
    if (blockStatus.status === SUCCESS) {
      if (blockStatus.data !== undefined) {
        setTitle(`${plot?.identifier} - ${blockStatus.data.identifier}`);
      }
    } else {
      const message = blockStatus.detail
        ? blockStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  useEffect(() => {
    setEditingPlot(plot ? plot : newPlot());
    if (plot?.blockId) {
      fetchBlockAndSetTitle(plot.blockId);
    } else {
      setTitle(plot?.identifier || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plot]);

  return (
    <CModal
      show={show}
      className={`modal-${color}`}
      onClosed={onCancel}
      size="xl"
    >
      <CModalHeader closeButton>
        <CModalTitle>Confirmación</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <h2>{message2}</h2>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onDeleteClick} color={color}>
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Guardando..." : "Confirmar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default PlotToggleStatusModal;
