import {
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CInputFile,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

import { warningAlert } from "../utils/messages";
import { FieldErrors } from "../form/FieldErrors";
import Errors, { getFieldErrors } from "../../models/errors";
import { getAbsoluteUrl } from "../../api/urls";
import { fetchAuthenticated } from "../../api/utils";

interface RecordImportModalProps {
  show: boolean;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const RecordImportModal: React.FC<RecordImportModalProps> = ({
  show,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = "Suba el Archivo xlsx correspondiente para importar";

  const [errors] = useState<Errors>({});

  const [loadedXlsxPath, setLoadedXlsxPath] = useState<string | undefined>("");
  const xlsx = useRef<HTMLInputElement>(null);
  const xlsxFileName = useRef<string>("");
  const xlsxUploaded = useRef<boolean>(false);
  const xlsxModified = useRef<boolean>(false);

  const onXLSXChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    xlsxModified.current = true;
    let fileParts = e.target.value.split("\\");
    xlsxFileName.current = fileParts[fileParts.length - 1];
    setLoadedXlsxPath(xlsxFileName.current);
  };

  const handleXLSXUpload = async () => {
    if (!xlsx.current || !xlsx.current.files || !xlsx.current.files[0]) {
      warningAlert("Debe seleccionar un archivo xlsx");
      return false;
    }

    const formData = new FormData();
    formData.append("file", xlsx.current.files[0]);

    setSubmitting(true);

    const url = getAbsoluteUrl("/land_developments/upload_xlsx/");
    const uploadStatus = await fetchAuthenticated(url, {
      method: "POST",
      body: formData,
    });

    if (uploadStatus.status === 200) {
      onSuccess();
      return true;
    } else {
      const errorData = await uploadStatus.json();
      if (errorData.detail) {
        warningAlert(errorData.detail);
      } else {
        warningAlert("Ocurrió un error durante la carga del archivo.");
      }
      return false;
    }
  };
  const onSave = async () => {
    const success = await handleXLSXUpload();

    if (success) {
      clearForm();
    }
    setSubmitting(false);
  };

  const clearForm = () => {
    setLoadedXlsxPath("");
    if (xlsx.current) {
      xlsx.current.value = "";
    }
    xlsxFileName.current = "";
    xlsxUploaded.current = false;
    xlsxModified.current = false;
  };

  return (
    <CModal show={show} className="modal-success" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Importar Datos</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <h5>
                La importación de archivos puede demorar unos minutos. Por favor
                espere
              </h5>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <h5>
                Descargue la plantilla xlsx para la importación{" "}
                <a
                  href={"/loteamiento_import.xlsx"}
                  download="loteamiento_import"
                >
                  Aquí
                </a>
              </h5>
            </CCol>
          </CRow>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>archivo excel (.xlsx):</CLabel>
              </CCol>
              <CCol>
                <CInputFile
                  accept=".xlsx"
                  type="file"
                  innerRef={xlsx}
                  onChange={onXLSXChange}
                ></CInputFile>
                {loadedXlsxPath ? (
                  <small>
                    Seleccionado actualmente:
                    {loadedXlsxPath}
                  </small>
                ) : (
                  <></>
                )}

                <FieldErrors
                  errors={getFieldErrors("xlsxFile", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onSave} color="success">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Guardando..." : "Importar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default RecordImportModal;
