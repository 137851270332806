import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Polygon,
  Tooltip,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { LatLngExpression, LatLngBounds } from "leaflet";
import { useLocation, useParams } from "react-router-dom";
import { getItem, getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { warningAlert } from "../utils/messages";
import Plot from "../../models/plots";
import { Spinner } from "react-bootstrap";
import LandDevelopment from "../../models/land_development";
const PLOTS_LIMIT = 10000; //basically unlimitted
const TOOLTIP_TIME = 5000;
const MapPage: React.FC = () => {
  const [landDevelopment, setLandDevelopment] = useState<
    LandDevelopment | undefined
  >(undefined);
  const [plots, setPlots] = useState<Plot[]>([]);
  const [bounds, setBounds] = useState<LatLngBounds | null>(null);
  const [loading, setLoading] = useState(true);

  const [activeTooltip, setActiveTooltip] = useState<number | null>(null);
  const [tooltipTimeout, setTooltipTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const getInitialValues = async () => {
    setLoading(true);
    const plotsPromise = fetchPlots(Number(id));
    const landDevelopmentPromise = fetchLandDevelopment(Number(id));
    await plotsPromise;
    await landDevelopmentPromise;
    setLoading(false);
  };

  const fetchLandDevelopment = async (id: number) => {
    const landDevelopmentStatus = await getItem<LandDevelopment>(
      `/land_developments/${id}/`
    );
    if (landDevelopmentStatus.status === SUCCESS) {
      if (landDevelopmentStatus.data !== undefined) {
        setLandDevelopment(landDevelopmentStatus.data);
      }
    } else {
      const message = landDevelopmentStatus.detail
        ? landDevelopmentStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const fetchPlots = async (id: number) => {
    const urlParams = new Map();
    const limit = PLOTS_LIMIT;
    const offset = 0;
    urlParams.set("land_development_id", id.toString());
    const plotsStatus = await getList<Plot>(
      "/plots/",
      limit,
      offset,
      urlParams
    );
    if (plotsStatus.status === SUCCESS) {
      if (plotsStatus.data !== undefined) {
        const fetchedPlots = plotsStatus.data.items;
        setPlots(fetchedPlots);
        calculateBounds(fetchedPlots);
      }
    } else {
      const message = plotsStatus.detail
        ? plotsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const calculateBounds = (plots: Plot[]) => {
    if (plots.length === 0) return;

    const latitudes: number[] = [];
    const longitudes: number[] = [];

    plots.forEach((plot) => {
      plot.plotPoints?.forEach((point) => {
        if (point.latitude !== undefined && point.longitude !== undefined) {
          latitudes.push(point.latitude);
          longitudes.push(point.longitude);
        }
      });
    });

    if (latitudes.length > 0 && longitudes.length > 0) {
      const minLat = Math.min(...latitudes);
      const maxLat = Math.max(...latitudes);
      const minLng = Math.min(...longitudes);
      const maxLng = Math.max(...longitudes);

      const newBounds = new LatLngBounds([minLat, minLng], [maxLat, maxLng]);

      setBounds(newBounds);
    }
  };

  const handleTooltipOpen = (plotIndex: number) => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout);
    }

    if (plotIndex !== activeTooltip) setActiveTooltip(plotIndex);

    const timeout = setTimeout(() => {
      setActiveTooltip(null);
    }, TOOLTIP_TIME);

    setTooltipTimeout(timeout);
  };

  useEffect(() => {
    if (id === undefined || isNaN(Number(id))) {
      setLoading(false);

      return;
    }
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Spinner
        animation="grow"
        style={{
          height: "17px",
          width: "17px",
          marginTop: "auto",
          marginBottom: "auto",
          marginRight: "10px",
        }}
      />
    );
  }
  const mapHeight = location.pathname.includes("/internal_map/")
    ? "100vh"
    : "75vh";

  return (
    <div style={{ position: "relative" }}>
      <MapContainer
        center={[51.51, -0.12]}
        zoom={13}
        style={{ height: mapHeight, width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {bounds && <MapBoundsSetter bounds={bounds} />}
        {plots.map((plot, index) => {
          if (!plot.plotPoints || plot.plotPoints.length < 3) {
            return null;
          }
          const sortedPoints = plot.plotPoints.sort(
            (a, b) => (a.order ?? 0) - (b.order ?? 0)
          );

          const polygonCoordinates: LatLngExpression[] = sortedPoints.map(
            (point) => [point.latitude ?? 0, point.longitude ?? 0]
          );

          const color = plot.isTaken ? "red" : "green";

          const tooltipContent = `
          ${plot.identifier ?? ""}\n
          ${plot.blockIdentifier ?? ""}
        `.trim();
          const descriptionLink = landDevelopment?.description
            ? `<br/><a href="${landDevelopment.description}" target="_blank" rel="noopener noreferrer">Contacto</a>`
            : "";
          return (
            <Polygon
              key={index}
              positions={polygonCoordinates}
              pathOptions={{ color }}
              eventHandlers={{
                mouseover: () => handleTooltipOpen(index),
              }}
            >
              <Tooltip
                key={index.toString() + (activeTooltip || "")}
                interactive
                permanent={activeTooltip === index}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${tooltipContent}${
                      !plot.isTaken ? descriptionLink : ""
                    }`,
                  }}
                />
              </Tooltip>
            </Polygon>
          );
        })}
      </MapContainer>
      <div
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          backgroundColor: "white",
          padding: "5px 10px",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
        }}
      >
        <div>
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              backgroundColor: "red",
              marginRight: "5px",
            }}
          ></span>
          NO Disponible
        </div>
        <div>
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              backgroundColor: "green",
              marginRight: "5px",
            }}
          ></span>
          Disponible
        </div>
      </div>
    </div>
  );
};

const MapBoundsSetter: React.FC<{ bounds: LatLngBounds }> = ({ bounds }) => {
  const map = useMap();

  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds);
    }
  }, [bounds, map]);

  return null;
};

export default MapPage;
