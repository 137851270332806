import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import LandDevelopmentForm from "./LandDevelopmentForm";
import LandDevelopment, {
  newLandDevelopment,
} from "../../models/land_development";

interface LandDevelopmentUpdateModalProps {
  show: boolean;
  landDevelopment?: LandDevelopment;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const LandDevelopmentUpdateModal: React.FC<LandDevelopmentUpdateModalProps> = ({
  show,
  landDevelopment,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialLandDevelopment, setInitialLandDevelopment] =
    useState<LandDevelopment>(
      landDevelopment ? landDevelopment : newLandDevelopment()
    );

  const modalTitle = landDevelopment
    ? `Editar loteamiento: ${landDevelopment.identifier}`
    : `Crear loteamiento nuevo`;

  const onClose = () => {
    setInitialErrors({});
    setInitialLandDevelopment(newLandDevelopment());
    onCancel();
  };
  useEffect(() => {
    setInitialLandDevelopment(
      landDevelopment ? landDevelopment : newLandDevelopment()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landDevelopment]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <LandDevelopmentForm
            initialLandDevelopment={initialLandDevelopment}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default LandDevelopmentUpdateModal;
