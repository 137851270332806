import LandDevelopmentList from "../components/land_developments/LandDevelopmentCrud";

const LandDevelopmentCrudPage = () => {
  return (
    <>
      <LandDevelopmentList />
    </>
  );
};

export default LandDevelopmentCrudPage;
