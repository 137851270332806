import {
  CBadge,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CTooltip,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getItem, getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";

import LandDevelopment, {
  newLandDevelopment,
} from "../../models/land_development";
import Block from "../../models/blocks";
import Plot from "../../models/plots";
import { BsFillPlusCircleFill } from "react-icons/bs";
import LandDevelopmentAddBlockModal from "./LandDevelopmentAddBlockModal";
import BlockDeleteModal from "../blocks/BlockDeleteModal";
import { defaultValueOnUndefined } from "../../utils/fields";
import BlockUpdateModal from "../blocks/BlockUpdateModal";
import PlotUpdateModal from "../plots/PlotUpdateModal";
import PlotDeleteModal from "../plots/PlotDeleteModal";
import PlotToggleStatusModal from "../plots/PlotToggleStatusModal";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import {
  BLOCKS_CREATE,
  BLOCKS_DESTROY,
  BLOCKS_UPDATE,
  PLOTS_CREATE,
  PLOTS_DESTROY,
  PLOTS_STATUS,
  PLOTS_UPDATE,
} from "../../auth/permissions";

const BLOCKS_LIMIT = 10000; //basically unlimitted

const LandDevelopmentDetail = () => {
  const plotTablesfields = [
    {
      key: "id",
      _classes: ["text-center", "align-middle"],
      label: "id(PK)",
    },
    {
      key: "identifier",
      _classes: ["text-center", "align-middle"],
      label: "Lote",
    },
    {
      key: "isTaken",
      _classes: ["text-center", "align-middle"],
      label: "Estado Actual",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const { id } = useParams<{ id: string }>();

  const [landDevelopment, setLandDevelopment] = useState<
    LandDevelopment | undefined
  >(newLandDevelopment());

  const [selectedBlock, setSelectedBlock] = useState<Block | undefined>(
    undefined
  );
  const [selectedPlot, setSelectedPlot] = useState<Plot | undefined>(undefined);

  const [showAddBlockModal, setShowAddBlockModal] = useState(false);
  const [showBlockEditModal, setShowBlockEditModal] = useState(false);
  const [showDeleteBlockModal, setShowDeleteBlockModal] = useState(false);
  const [showAddPlotModal, setShowAddPlotModal] = useState(false);
  const [showDeletePlotModal, setShowDeletePlotModal] = useState(false);
  const [showPlotToggleModal, setShowPlotToggleModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const history = useHistory();

  const onAddBlockClick = () => {
    setShowAddBlockModal(true);
  };

  const onAddBlockCancel = () => {
    setShowAddBlockModal(false);
  };

  const onAddBlockSuccess = () => {
    successAlert("Se han agregado manzanas con éxito!");
    setShowAddBlockModal(false);
    setBlocks([]);
    fetchBlocks(Number(id));
  };

  const onEditBlockClick = (block: Block) => {
    setSelectedBlock(block);
    setShowBlockEditModal(true);
  };

  const oneditBlockCancel = () => {
    setShowBlockEditModal(false);
  };

  const onEditBlockSuccess = () => {
    successAlert("Se ha modificado una manzana con éxito!");
    setShowBlockEditModal(false);
    setBlocks([]);
    fetchBlocks(Number(id));
  };

  const onDeleteBlockClick = (block: Block) => {
    setSelectedBlock(block);
    setShowDeleteBlockModal(true);
  };

  const onDeleteBlockCancel = () => {
    setShowDeleteBlockModal(false);
  };

  const onDeleteBlockSuccess = () => {
    successAlert("Se ha borrado una manzana con éxito!");
    setShowDeleteBlockModal(false);
    setBlocks([]);
    fetchBlocks(Number(id));
  };

  const onAddPlotClick = () => {
    setSelectedPlot(undefined);
    setShowAddPlotModal(true);
  };

  const onAddPlotCancel = () => {
    setShowAddPlotModal(false);
  };

  const onUpdatePlotClick = (plot: Plot) => {
    setSelectedPlot(plot);
    setShowAddPlotModal(true);
  };

  const onAddPlotSuccess = () => {
    successAlert("Se ha aghregado un lote con éxito!");
    setShowAddPlotModal(false);
    setBlocks([]);
    fetchBlocks(Number(id));
  };

  const onDeletePlotClick = (plot: Plot) => {
    setSelectedPlot(plot);
    setShowDeletePlotModal(true);
  };

  const onDeletePlotCancel = () => {
    setShowDeletePlotModal(false);
  };

  const onDeletePlotSuccess = () => {
    successAlert("Se ha borrado un lote con éxito!");
    setShowDeletePlotModal(false);
    setBlocks([]);
    fetchBlocks(Number(id));
  };

  const onTogglePlotClick = (plot: Plot) => {
    setSelectedPlot(plot);
    setShowPlotToggleModal(true);
  };

  const onTogglePlotCancel = () => {
    setShowPlotToggleModal(false);
  };

  const onTogglePlotSuccess = () => {
    successAlert("Se ha cambiado el estado de un lote con éxito!");
    setShowPlotToggleModal(false);
    setBlocks([]);
    fetchBlocks(Number(id));
  };

  const fetchLandDevelopment = async (id: number) => {
    const landDevelopmentStatus = await getItem<LandDevelopment>(
      `/land_developments/${id}/`
    );
    if (landDevelopmentStatus.status === SUCCESS) {
      if (landDevelopmentStatus.data !== undefined) {
        setLandDevelopment(landDevelopmentStatus.data);
      }
    } else {
      const message = landDevelopmentStatus.detail
        ? landDevelopmentStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const fetchBlocks = async (id: number) => {
    const urlParams = new Map();
    const limit = BLOCKS_LIMIT;
    const offset = 0;
    urlParams.set("land_development_id", id.toString());
    const paymentsStatus = await getList<Block>(
      "/blocks/",
      limit,
      offset,
      urlParams
    );
    if (paymentsStatus.status === SUCCESS) {
      if (paymentsStatus.data !== undefined) {
        setBlocks(paymentsStatus.data.items);
      }
    } else {
      const message = paymentsStatus.detail
        ? paymentsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const getInitialValues = async () => {
    setLoading(true);
    const landDevelopmentPromise = fetchLandDevelopment(Number(id));
    const blocksPromise = fetchBlocks(Number(id));
    await landDevelopmentPromise;
    await blocksPromise;
    setLoading(false);
  };

  useEffect(() => {
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      fetchBlocks(Number(id));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>
                  Configuracion de Loteamiento: {landDevelopment?.identifier}
                </h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent permissionName={BLOCKS_CREATE}>
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddBlockClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Manzanas
                  </CButton>
                </PermissionRequiredComponent>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent permissionName={PLOTS_CREATE}>
                  <CButton
                    color="success"
                    className="float-right"
                    onClick={onAddPlotClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Lote
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <b>Link de Contacto: </b>
                  {defaultValueOnUndefined("-", landDevelopment?.description)}
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <b>Observación: </b>
                  {defaultValueOnUndefined("-", landDevelopment?.obs)}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      {blocks.map((block, ix) => {
        return (
          <CRow key={ix}>
            <CCol lg="12">
              <CCard>
                <CCardHeader className="d-flex flex-row mb-3">
                  <div className="p-2 ">
                    <h3>{block.identifier}</h3>
                  </div>
                  <div className="p-2 ">
                    <PermissionRequiredComponent permissionName={BLOCKS_UPDATE}>
                      <CButton
                        color="warning"
                        className="float-right"
                        onClick={() => onEditBlockClick(block)}
                      >
                        <i className="fa fa-pencil"></i>&nbsp; Editar Manzana
                      </CButton>
                    </PermissionRequiredComponent>
                  </div>
                  <div className="p-2 ">
                    <PermissionRequiredComponent
                      permissionName={BLOCKS_DESTROY}
                    >
                      <CButton
                        color="danger"
                        className="float-right"
                        onClick={() => onDeleteBlockClick(block)}
                      >
                        <i className="fa fa-trash"></i>&nbsp; Borrar Manzana
                      </CButton>
                    </PermissionRequiredComponent>
                  </div>
                </CCardHeader>
                <CCardBody>
                  <div>
                    <CDataTable
                      noItemsView={
                        <h2 className="text-center">Sin Resultados</h2>
                      }
                      addTableClasses={"table-fixed"}
                      fields={plotTablesfields}
                      items={block.plots}
                      striped
                      border
                      loading={loading}
                      responsive
                      scopedSlots={{
                        isTaken: (item: Plot) => {
                          const badgeColor = item.isTaken
                            ? "danger"
                            : "success";
                          const badgeText = item.isTaken
                            ? "NO DISPONIBLE"
                            : "DISPONIBLE";
                          return (
                            <td className="text-center align-middle">
                              <CButtonGroup>
                                <CBadge color={badgeColor}>{badgeText}</CBadge>
                              </CButtonGroup>
                            </td>
                          );
                        },
                        actions: (item: Plot) => {
                          const buttonTooltipText = item.isTaken
                            ? "Marcar Como disponible"
                            : "Marcar como NO disponible";
                          const buttonColor = item.isTaken
                            ? "success"
                            : "secondary";
                          const buttonIcon = item.isTaken
                            ? "fa fa-arrow-up"
                            : "fa fa-arrow-down";
                          const toggleStatusButton = (
                            <PermissionRequiredComponent
                              permissionName={PLOTS_STATUS}
                            >
                              <CTooltip content={buttonTooltipText}>
                                <CButton
                                  className="text-white"
                                  color={buttonColor}
                                  onClick={() => {
                                    onTogglePlotClick(item);
                                  }}
                                >
                                  <i className={buttonIcon}></i>
                                </CButton>
                              </CTooltip>
                            </PermissionRequiredComponent>
                          );
                          const editButton = (
                            <PermissionRequiredComponent
                              permissionName={PLOTS_UPDATE}
                            >
                              <CTooltip content={"Editar"}>
                                <CButton
                                  className="text-white"
                                  color="warning"
                                  onClick={() => {
                                    onUpdatePlotClick(item);
                                  }}
                                >
                                  <i className="fa fa-pencil"></i>
                                </CButton>
                              </CTooltip>
                            </PermissionRequiredComponent>
                          );
                          const deleteButton = (
                            <PermissionRequiredComponent
                              permissionName={PLOTS_DESTROY}
                            >
                              <CTooltip content={"Eliminar"}>
                                <CButton
                                  className="text-white"
                                  color="danger"
                                  onClick={() => {
                                    onDeletePlotClick(item);
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </CButton>
                              </CTooltip>
                            </PermissionRequiredComponent>
                          );

                          return (
                            <td className="text-right">
                              <CButtonGroup>
                                {toggleStatusButton}
                                {editButton}
                                {deleteButton}
                              </CButtonGroup>
                            </td>
                          );
                        },
                      }}
                    />
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        );
      })}
      <LandDevelopmentAddBlockModal
        show={showAddBlockModal}
        onCancel={onAddBlockCancel}
        onSuccess={onAddBlockSuccess}
        landDevelopment={landDevelopment}
      />
      <BlockDeleteModal
        show={showDeleteBlockModal}
        onCancel={onDeleteBlockCancel}
        onSuccess={onDeleteBlockSuccess}
        block={selectedBlock}
      />
      <BlockUpdateModal
        show={showBlockEditModal}
        onCancel={oneditBlockCancel}
        onSuccess={onEditBlockSuccess}
        block={selectedBlock}
      />
      <PlotUpdateModal
        show={showAddPlotModal}
        onCancel={onAddPlotCancel}
        onSuccess={onAddPlotSuccess}
        plot={selectedPlot}
      />
      <PlotDeleteModal
        show={showDeletePlotModal}
        onCancel={onDeletePlotCancel}
        onSuccess={onDeletePlotSuccess}
        plot={selectedPlot}
      />
      <PlotToggleStatusModal
        show={showPlotToggleModal}
        taken={selectedPlot?.isTaken}
        onCancel={onTogglePlotCancel}
        onSuccess={onTogglePlotSuccess}
        plot={selectedPlot}
      />
    </>
  );
};

export default LandDevelopmentDetail;
