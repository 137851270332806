import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
} from "@coreui/react";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import User from "../../models/user";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import UserDeleteModal from "./UserDeleteModal";
import UserUpdateModal from "./UserUpdateModal";
import UserChangePasswordModal from "./UserChangePasswordModal";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import {
  USERS_CREATE,
  USERS_DESTROY,
  USERS_UPDATE,
} from "../../auth/permissions";

const ITEMS_PER_PAGE = 10;

const UserList = () => {
  const fields = [
    {
      key: "firstName",
      _classes: ["text-center"],
      label: "Nombre",
    },
    {
      key: "lastName",
      _classes: ["text-center"],
      label: "Apellido",
    },
    {
      key: "email",
      _classes: ["text-center"],
      label: "Email",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showUserEditModal, setShowUserEditModal] = useState(false);
  const [showUserDeleteModal, setShowUserDeleteModal] = useState(false);
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const userId = useSelector((state: RootState) => state.user.id);

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    return map;
  };

  const fetchUsers = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const usersStatus = await getList<User>(
      "/users/",
      limit,
      offset,
      urlParams
    );
    if (usersStatus.status === SUCCESS) {
      if (usersStatus.data !== undefined) {
        const count = usersStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setUsers(usersStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = usersStatus.detail
        ? usersStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setShowUserEditModal(true);
  };

  const onUpdateClick = (user: User) => {
    setUser(user);
    setShowUserEditModal(true);
  };

  const onDeleteClick = (user: User) => {
    setUser(user);
    setShowUserDeleteModal(true);
  };

  const onChangePasswordClick = (user: User) => {
    setUser(user);
    setShowPasswordChangeModal(true);
  };

  const onUserEditCancel = () => {
    setUser(undefined);
    setShowUserEditModal(false);
  };

  const onUserEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowUserEditModal(false);
    setUsers([]);
    setLoading(true);
    fetchUsers();
  };

  const onUserDeleteCancel = () => {
    setUser(undefined);
    setShowUserDeleteModal(false);
  };

  const onUserDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowUserDeleteModal(false);
    setUsers([]);
    setLoading(true);
    fetchUsers();
  };

  const onUserChangePasswordCancel = () => {
    setUser(undefined);
    setShowPasswordChangeModal(false);
  };

  const onUserChangePasswordSuccess = () => {
    successAlert("Contraseña cambiada con éxito!");
    setShowPasswordChangeModal(false);
    setUsers([]);
    setLoading(true);
    fetchUsers();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("search", search);
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("users")) {
        return;
      }
      fetchUsers();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Usuarios</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent permissionName={USERS_CREATE}>
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={users}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    firstName: (item: User) => {
                      if (item.firstName === null) {
                        return <td></td>;
                      } else {
                        return <td>{item.firstName}</td>;
                      }
                    },
                    lastName: (item: User) => {
                      if (item.lastName === null) {
                        return <td></td>;
                      } else {
                        return <td>{item.lastName}</td>;
                      }
                    },
                    actions: (item: User) => {
                      const changePasswordButton = (
                        <PermissionRequiredComponent
                          permissionName={USERS_UPDATE}
                        >
                          <CButton
                            className="text-white"
                            color="success"
                            onClick={() => {
                              onChangePasswordClick(item);
                            }}
                          >
                            <i className="fa fa-key"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={USERS_UPDATE}
                        >
                          <CButton
                            className="text-white"
                            color="warning"
                            onClick={() => {
                              onUpdateClick(item);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );
                      const deleteButton = (
                        <PermissionRequiredComponent
                          permissionName={USERS_DESTROY}
                        >
                          <CButton
                            className="text-white"
                            color="danger"
                            onClick={() => {
                              onDeleteClick(item);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {userId !== item.id ? changePasswordButton : <></>}
                            {editButton}
                            {userId !== item.id ? deleteButton : <></>}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <UserUpdateModal
        show={showUserEditModal}
        onCancel={onUserEditCancel}
        onSuccess={onUserEditSuccess}
        user={user}
      />
      <UserDeleteModal
        show={showUserDeleteModal}
        onCancel={onUserDeleteCancel}
        onSuccess={onUserDeleteSuccess}
        user={user}
      />
      <UserChangePasswordModal
        show={showPasswordChangeModal}
        onCancel={onUserChangePasswordCancel}
        onSuccess={onUserChangePasswordSuccess}
        user={user}
      />
    </>
  );
};

export default UserList;
