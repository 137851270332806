import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
  CSidebarNavItem,
} from "@coreui/react";

import {
  minimizeDashboard,
  showDashboard,
} from "../../redux/actions/dashboard";
import { RootState } from "../../store";

// sidebar nav config
import { getRouteName, getRoutePath } from "../../routes";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import {
  LAND_DEVELOPMENTS_RETRIEVE,
  USERS_RETRIEVE,
} from "../../auth/permissions";

const Sidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state: RootState) => state.dashboard.sidebarShow);
  const minimize = useSelector(
    (state: RootState) => state.dashboard.sidebarMinimize
  );

  return (
    <CSidebar
      show={show}
      onShowChange={(val: boolean) => {
        dispatch(showDashboard(val));
        window.localStorage.setItem("sidebarShow", val ? "true" : "false");
      }}
      minimize={minimize}
      onMinimizeChange={(val: boolean) => {
        dispatch(minimizeDashboard(!val));
        window.localStorage.setItem("sidebarMinimize", !val ? "true" : "false");
      }}
      dropdownMode={"noAction"}
    >
      <CSidebarBrand className="d-md-down-none" to="/"></CSidebarBrand>
      <CSidebarNav>
        <CSidebarNavItem
          name={getRouteName("dashboard")}
          to={getRoutePath("dashboard")}
          icon={"cil-home"}
        ></CSidebarNavItem>
        <PermissionRequiredComponent
          permissionName={LAND_DEVELOPMENTS_RETRIEVE}
        >
          <CSidebarNavItem
            name={getRouteName("landDevelopments")}
            to={getRoutePath("landDevelopments")}
            icon={<i className="c-sidebar-nav-icon fa fa-dollar-sign"></i>}
          ></CSidebarNavItem>
        </PermissionRequiredComponent>
        <PermissionRequiredComponent permissionName={USERS_RETRIEVE}>
          <CSidebarNavItem
            name={getRouteName("users")}
            to={getRoutePath("users")}
            icon={"cil-people"}
          ></CSidebarNavItem>
        </PermissionRequiredComponent>
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default Sidebar;
