export const emptyValueOnUndefined = <Type>(value?: Type) => {
  if (value === undefined) {
    return "";
  }

  return value;
};

export const emptyValueOnNull = <Type>(value: Type | null) => {
  if (value === null) {
    return "";
  }

  return value;
};

export const undefinedOnNull = <Type>(value: Type | null) => {
  if (value === null) {
    return undefined;
  }

  return value;
};

export const defaultValueOnUndefined = <Type>(
  defaultValue: string,
  value?: Type
) => {
  if (value === undefined) {
    return defaultValue;
  }

  return value;
};

export function showNumberWithDecimalPoint(
  number: number,
  decimalPlaces: number
): string {
  if (number % 1 !== 0) {
    return number.toFixed(decimalPlaces);
  } else {
    // Si el número es entero, devuelve el número como una cadena
    return number.toFixed(0);
  }
}
