export const LAND_DEVELOPMENTS_GROUP_LABEL = "Loteamientos";
export const LAND_DEVELOPMENTS_RETRIEVE = "land_developments.retrieve";
export const LAND_DEVELOPMENTS_RETRIEVE_LABEL = "Leer";
export const LAND_DEVELOPMENTS_CREATE = "land_developments.create";
export const LAND_DEVELOPMENTS_CREATE_LABEL = "Crear";
export const LAND_DEVELOPMENTS_UPDATE = "land_developments.update";
export const LAND_DEVELOPMENTS_UPDATE_LABEL = "Editar";
export const LAND_DEVELOPMENTS_DESTROY = "land_developments.destroy";
export const LAND_DEVELOPMENTS_DESTROY_LABEL = "Eliminar";

export const BLOCKS_GROUP_LABEL = "Manzanas";
export const BLOCKS_RETRIEVE = "blocks.retrieve";
export const BLOCKS_RETRIEVE_LABEL = "Leer";
export const BLOCKS_CREATE = "blocks.create";
export const BLOCKS_CREATE_LABEL = "Crear";
export const BLOCKS_UPDATE = "blocks.update";
export const BLOCKS_UPDATE_LABEL = "Editar";
export const BLOCKS_DESTROY = "blocks.destroy";
export const BLOCKS_DESTROY_LABEL = "Eliminar";

export const PLOTS_GROUP_LABEL = "Lotes";
export const PLOTS_STATUS = "plots.status";
export const PLOTS_STATUS_LABEL = "Disponibilizar";
export const PLOTS_CREATE = "plots.create";
export const PLOTS_CREATE_LABEL = "Crear";
export const PLOTS_UPDATE = "plots.update";
export const PLOTS_UPDATE_LABEL = "Editar";
export const PLOTS_DESTROY = "plots.destroy";
export const PLOTS_DESTROY_LABEL = "Eliminar";

export const USERS_GROUP_LABEL = "Usuarios";
export const USERS_RETRIEVE = "users.retrieve";
export const USERS_RETRIEVE_LABEL = "Leer";
export const USERS_CREATE = "users.create";
export const USERS_CREATE_LABEL = "Crear";
export const USERS_UPDATE = "users.update";
export const USERS_UPDATE_LABEL = "Editar";
export const USERS_DESTROY = "users.destroy";
export const USERS_DESTROY_LABEL = "Eliminar";

export const PERMISSION_GROUPS: Map<
  string,
  { value: string; label: string }[]
> = new Map([
  [
    LAND_DEVELOPMENTS_GROUP_LABEL,
    [
      {
        value: LAND_DEVELOPMENTS_RETRIEVE,
        label: LAND_DEVELOPMENTS_RETRIEVE_LABEL,
      },
      {
        value: LAND_DEVELOPMENTS_CREATE,
        label: LAND_DEVELOPMENTS_CREATE_LABEL,
      },
      {
        value: LAND_DEVELOPMENTS_UPDATE,
        label: LAND_DEVELOPMENTS_UPDATE_LABEL,
      },
      {
        value: LAND_DEVELOPMENTS_DESTROY,
        label: LAND_DEVELOPMENTS_DESTROY_LABEL,
      },
    ],
  ],
  [
    BLOCKS_GROUP_LABEL,
    [
      {
        value: BLOCKS_RETRIEVE,
        label: BLOCKS_RETRIEVE_LABEL,
      },
      {
        value: BLOCKS_CREATE,
        label: BLOCKS_CREATE_LABEL,
      },
      {
        value: BLOCKS_UPDATE,
        label: BLOCKS_UPDATE_LABEL,
      },
      {
        value: BLOCKS_DESTROY,
        label: BLOCKS_DESTROY_LABEL,
      },
    ],
  ],
  [
    PLOTS_GROUP_LABEL,
    [
      {
        value: PLOTS_STATUS,
        label: PLOTS_STATUS_LABEL,
      },
      {
        value: PLOTS_CREATE,
        label: PLOTS_CREATE_LABEL,
      },
      {
        value: PLOTS_UPDATE,
        label: PLOTS_UPDATE_LABEL,
      },
      {
        value: PLOTS_DESTROY,
        label: PLOTS_DESTROY_LABEL,
      },
    ],
  ],
  [
    USERS_GROUP_LABEL,
    [
      {
        value: USERS_RETRIEVE,
        label: USERS_RETRIEVE_LABEL,
      },
      {
        value: USERS_CREATE,
        label: USERS_CREATE_LABEL,
      },
      {
        value: USERS_UPDATE,
        label: USERS_UPDATE_LABEL,
      },
      {
        value: USERS_DESTROY,
        label: USERS_DESTROY_LABEL,
      },
    ],
  ],
]);
