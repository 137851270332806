import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import BlockForm from "./BlockForm";
import Block, { newBlock } from "../../models/blocks";

interface BlockUpdateModalProps {
  show: boolean;
  block?: Block;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const BlockUpdateModal: React.FC<BlockUpdateModalProps> = ({
  show,
  block,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialBlock, setInitialBlock] = useState<Block>(
    block ? block : newBlock()
  );

  const modalTitle = block
    ? `Editar Manzana: ${block.identifier}`
    : `Crear Manzana nueva`;

  const onClose = () => {
    setInitialErrors({});
    setInitialBlock(newBlock());
    onCancel();
  };
  useEffect(() => {
    setInitialBlock(block ? block : newBlock());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <BlockForm
            initialBlock={initialBlock}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default BlockUpdateModal;
