import {
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, warningAlert } from "../utils/messages";
import LandDevelopment from "../../models/land_development";
import { FieldErrors } from "../form/FieldErrors";
import Errors, { getFieldErrors } from "../../models/errors";

interface LandDevelopmentAddBlockModalProps {
  show: boolean;
  landDevelopment?: LandDevelopment;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const LandDevelopmentAddBlockModal: React.FC<
  LandDevelopmentAddBlockModalProps
> = ({ show, landDevelopment, onCancel, onSuccess }) => {
  const [submitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState<number>(0);
  const [errors, setErrors] = useState<Errors>({});

  const message = landDevelopment
    ? `¿Cuantas Manzanas desea agregar al Loteamiento: ${landDevelopment.identifier}?`
    : "¿Cuantas Manzanas desea agregar al Loteamiento?";

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let amount: number | undefined = undefined;
    if (!isNaN(parseInt(e.target.value))) {
      amount = parseInt(e.target.value);
    }
    if (amount) {
      setAmount(amount);
    }
  };

  const onSubmit = async () => {
    if (!amount) {
      warningAlert("Debe agregar al menos una Manzana");
      return;
    }
    if (landDevelopment && landDevelopment.id !== undefined) {
      let toSendData: {
        landDevelopmentId: number;
        blocksAmount: number;
      } = {
        landDevelopmentId: landDevelopment.id,
        blocksAmount: amount,
      };
      setSubmitting(true);
      const clientStatus = await createItem<{
        landDevelopmentId: number;
        blocksAmount: number;
      }>(`/land_developments/${landDevelopment.id}/add_blocks/`, toSendData);

      if (clientStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (clientStatus.detail !== undefined) {
          message = clientStatus.detail;
        }
        if (clientStatus.errors !== undefined) {
          setErrors(clientStatus.errors);
        }
        errorAlert(message);
      } else {
        setErrors({});
        setAmount(0);
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-primary" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Agregar Manzanas</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Cantidad de Manzanas:</CLabel>
              </CCol>
              <CCol>
                <CInput
                  type="number"
                  placeholder="Cantidad"
                  value={amount}
                  onChange={onAmountChange}
                ></CInput>
                <FieldErrors
                  errors={getFieldErrors("blocksAmount", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onSubmit} color="primary">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Eliminando..." : "Eliminar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default LandDevelopmentAddBlockModal;
