const DashboardPage = () => {
  return (
    <>
      <h1 style={{ marginLeft: "15px" }}>Bienvenido de nuevo a Land Plotter</h1>
      <h5 style={{ marginLeft: "15px" }}>
        Seleccione una opcion de la barra de navegacion izquierda
      </h5>
    </>
  );
};

export default DashboardPage;
