import { useCallback, useState } from "react";

import Errors from "../../models/errors";

import { CButton, CButtonGroup, CInput, CTooltip } from "@coreui/react";

import { PlotPoint } from "../../models/plots";

interface PlopPointFormProps {
  value?: PlotPoint;
  initialValue?: PlotPoint;
  initialErrors?: Errors;
  errors: Errors;
  disabled?: boolean;
  onChange: (plotPoint: PlotPoint) => void | Promise<void>;
  onDelete: (plotPoint: PlotPoint) => void | Promise<void>;
}

const PlopPointForm: React.FC<PlopPointFormProps> = ({
  value,
  initialErrors,
  initialValue,
  onChange,
  onDelete,
  errors,
  disabled,
}) => {
  const [editingItem, setEditingItem] = useState<PlotPoint>(value ? value : {});

  const onLatitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let latitude: number | undefined = undefined;
    if (!isNaN(parseFloat(e.target.value))) {
      latitude = parseFloat(e.target.value);
    }
    let newEditingItem: PlotPoint = { ...editingItem };
    if (latitude) {
      newEditingItem.latitude = latitude;
      setEditingItem(newEditingItem);
      onChange(newEditingItem);
    }
  };
  const onLongitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let longitude: number | undefined = undefined;
    if (!isNaN(parseFloat(e.target.value))) {
      longitude = parseFloat(e.target.value);
    }
    let newEditingItem: PlotPoint = { ...editingItem };
    if (longitude) {
      newEditingItem.longitude = longitude;
      setEditingItem(newEditingItem);
      onChange(newEditingItem);
    }
  };

  const onDeleteClick = useCallback(() => {
    onDelete(editingItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem, onDelete]);

  return (
    <tr style={{ verticalAlign: "middle", overflow: "hidden" }}>
      <td style={{ padding: 0, width: "70px" }}>
        <CInput
          type="number"
          value={editingItem.latitude}
          onChange={onLatitudeChange}
          step={"0.000001"}
          disabled={disabled}
        ></CInput>
      </td>
      <td style={{ padding: 0, width: "70px" }}>
        <CInput
          type="number"
          value={editingItem.longitude}
          onChange={onLongitudeChange}
          step={"0.000001"}
          disabled={disabled}
        ></CInput>
      </td>
      <td className="text-center" style={{ padding: 0, width: "60px" }}>
        <CButtonGroup>
          <CTooltip content="Borrar">
            <CButton
              className="text-white"
              color="danger"
              onClick={() => {
                onDeleteClick();
              }}
              disabled={disabled}
            >
              <i className="fa fa-trash"></i>
            </CButton>
          </CTooltip>
        </CButtonGroup>
      </td>
    </tr>
  );
};

export default PlopPointForm;
